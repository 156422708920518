/* stylelint-disable selector-class-pattern */
:global {
    :local(.container) {
        --body-quote-font-family: var(--font-dehors);
        --body-quote-text-transform: normal;
        --body-quote-font-size: 1.5em;

        background-image: url('../../assets/img/neige.webp');
        background-size: 800px 450px;

        .block-text {
            a {
                color: var(--body-primary-color);
                font-style: italic;
                font-weight: bold;
                text-decoration: none;
            }
        }

        .block-image {
            --body-block-margin-top: 4em;
            --body-block-margin-bottom: 4em;
        }

        .block-heading {
            h4 {
                max-width: 100%;
                font-family: var(--font-dehors);
                font-size: 2.2em;
                line-height: 1;
                text-transform: uppercase;
            }
        }

        .block-section {
            padding: 4em 0;
            margin-bottom: 0;
        }

        .block-quote {
            --body-quote-font-size: 1.5em;

            figure {
                padding-left: 1em;
                left: 0;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    height: 100%;
                    border-left: 0.2em solid var(--body-quote-border-color);
                    content: '';
                }
            }
        }

        [data-theme='section-brown'] {
            --article-text-color: #deeeff;
            --body-text-color: #deeeff;
            --body-primary-color: #4fb6ea;
            --body-quote-border-color: var(--body-primary-color);

            background: #28150b;
            color: var(--body-text-color);
        }

        [data-theme='section-blue'] {
            --article-text-color: #53311e;
            --body-text-color: #53311e;
            --body-primary-color: #deeeff;
            --body-quote-border-color: var(--body-primary-color);

            background: var(--article-background);
            background-color: #4fb6ea;
            background-size: 400px 400px;
            color: var(--body-text-color);
        }

        [data-theme='section-light-blue'] {
            --article-text-color: #53311e;
            --body-text-color: #53311e;
            --body-primary-color: #4fb6ea;
            --body-quote-border-color: var(--body-primary-color);

            background: var(--article-background);
            background-color: #deeeff;
            background-image: url('../../assets/img/neige.webp');
            background-size: 800px 450px;
            color: var(--body-text-color);
        }

        [data-theme='text-blue'] {
            --body-primary-color: #4fb6ea;

            color: #4fb6ea;
        }

        [data-theme='text-blue-light'] {
            --body-primary-color: #deeeff;

            color: #deeeff;
        }

        [data-theme='text-brown'] {
            --body-primary-color: #53311E;

            color: #53311E;
        }

        @media (--medium-viewport) {
            .block-heading {
                h4 {
                    max-width: 80%;
                    font-size: 3em;
                }
            }

            .block-quote {
                --body-quote-font-size: 2em;

                figure {
                    padding-left: 1em;
                    left: -1em;
                }
            }
        }
    }
}
